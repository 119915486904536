import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/webprojectcard.css"
import Particle12 from "../components/Particle12"
import SEO from "../components/SEO"
import { Link } from "gatsby"

const queue = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="Queue Programs"
          description="Programs of Queue under Data Structures and Algorithm with Python."
        />
        <Particle12></Particle12>
        <h6>s</h6>

        <section className="noSelect">
          <Link to="/dsa/">
            <button type="button" className="back-btn noSelect">
              <span>{"<"}= More Programs</span>
            </button>
          </Link>
        </section>

        <div className="programming-project">
          <h1>Queue</h1>
        </div>

        <section className="most-liked noSelect">
          <h3 className="total">Total Programs :- 6</h3>
          <div className="row">
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Basic Queue Operations</h3>
                </div>
                <p className="basic-op">
                  •Create a queue
                  <br />
                  •Perform Enqueue
                  <br />
                  •Perform Dequeue
                  <br />
                  •Display Queue
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Queue/Basic%20Queue%20Operations.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Representation of a Queue using array
                  </h3>
                </div>
                <p className="basic-op">
                  •Create a queue using array
                  <br />
                  •Do Enqueue
                  <br />
                  •Do Dequeue
                  <br />
                  •Print the queue
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Queue/Representation%20of%20a%20Queue%20using%20array.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Implementation of Queue using Stack
                  </h3>
                </div>
                <p className="basic-op">
                  •Create a queue using stack
                  <br />
                  •Do Enqueue
                  <br />
                  •Do Dequeue
                  <br />
                  •Print the queue
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Queue/Implementation%20of%20Queue%20%20using%20Stack.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Round Robin Algorithm</h3>
                </div>
                <p className="basic-op">
                  •Remember the Algorithm
                  <br />
                  •Find waiting time
                  <br />
                  •Find turn around time
                  <br />
                  •Don't forget about Brust time and quantum
                  <br />
                  •Find Avg time and print it.
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Queue/Round%20Robin%20Algorithm%20program.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Circular Queue</h3>
                </div>
                <p className="basic-op">
                  •Create a Circular queue, by defining a front and rear
                  <br />
                  •Do Enqueue and Dequeue
                  <br />
                  •Make sure when its empty or full
                  <br />
                  •Display it
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Queue/Circular%20Queue.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Priority Queue</h3>
                </div>
                <p className="basic-op">
                  •Remember its properties
                  <br />
                  •Create a priority queue
                  <br />
                  •Insert item
                  <br />
                  •Delete item
                  <br />
                  •Display it
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Queue/Priority%20Queue.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default queue
